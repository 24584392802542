import * as React from 'react'
import IndexLayout from '../layouts'

export default function Links() {
  return (
    <IndexLayout>
      <div className="links">
        <h1 className="sectionTitle">Links</h1>
        <ul>
          <li>
            <a href="http://www.familientreff-kirchbichl.at" target="_blank" rel="noreferrer">
              www.familientreff-kirchbichl.at
            </a>
          </li>
          <li>
            <a href="http://www.badhaering.at" target="_blank" rel="noreferrer">
              www.badhaering.at
            </a>
          </li>
          <li>
            <a href="https://verbindungsraum.at" target="_blank" rel="noreferrer">
              verbindungsraum.at
            </a>
          </li>
        </ul>
      </div>
    </IndexLayout>
  )
}
